import {Component, Input, OnInit} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {CreatorPreviewDialogComponent} from '../creator-preview-dialog/creator-preview-dialog.component';
import {CreatorService} from '../../services/creator.service';
import {prices} from '../../creatorData';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CreatorCartDialogComponent} from "../creator-cart-dialog/creator-cart-dialog.component";

@Component({
  selector: 'app-creator-actions',
  templateUrl: './creator-actions.component.html',
  styleUrls: ['./creator-actions.component.scss']
})
export class CreatorActionsComponent {
  @Input() stepper: MatStepper;
  @Input() stepCategory: FormGroup;
  @Input() stepView: FormGroup;
  @Input() stepDetails: FormGroup;

  constructor(
    private dialog: MatDialog,
  ) {
  }

  handlePrev(): void {
    this.stepper.selectedIndex -= 1;
  }

  handleNext(): void {
    const stepsValid = [this.stepCategory, this.stepView, this.stepDetails];
    const currentStep = stepsValid[this.stepper.selectedIndex];

    if (currentStep.valid) {
      this.stepper.selectedIndex += 1;
    } else {
      currentStep.markAllAsTouched();
    }
  }

  get isFirstStep(): boolean {
    return this.stepper.selectedIndex === 0;
  }

  get isLastStep(): boolean {
    return this.stepper.steps.length - 1 === this.stepper.selectedIndex;
  }

  openDialog(): void {
    const data = {
      ...this.stepCategory.get('category').value,
      ...this.stepView.get('view').value,
      ...this.stepDetails.get('details').value,
      price: prices[this.stepDetails.get('details.size').value]
    };

    this.dialog.open(CreatorCartDialogComponent, {
      data
    });
  }


  openPreview(): void {
    this.dialog.open(CreatorPreviewDialogComponent, {
      data: {
        image: this.stepView.get('view.image').value,
        text: this.stepView.get('view.text').value
      }
    });
  }
}
