<footer class="white-bg px-3 py-2 d-flex justify-content-between flex-column flex-sm-row align-items-center">
  <app-creator-price [controlSize]="stepDetails.get('details.size')"></app-creator-price>
  <aside class="d-flex align-items-center">
  </aside>
  <article class="d-flex align-items-center">
    <button class="ml-2 px-3 py-2 btn btn-main d-block d-lg-none" (click)="openPreview()">Podgląd</button>
    <button class="ml-2 px-3 py-2 btn btn-main d-none d-sm-flex" *ngIf="!isFirstStep" (click)="handlePrev()">Wstecz</button>
    <button class="ml-2 px-3 py-2 btn btn-main d-none d-sm-flex" *ngIf="!isLastStep" (click)="handleNext()">Dalej</button>
    <button class="ml-2 px-3 py-2 btn btn-main position-relative" *ngIf="isLastStep" (click)="openDialog()">Do koszyka</button>
  </article>
</footer>
