<app-creator-stepper
  [stepper]="stepper"
  [stepCategoryInvalid]="stepCategory.invalid"
  [stepViewInvalid]="stepView.invalid"
  [stepDetailsInvalid]="stepDetails.invalid">
</app-creator-stepper>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-4 p-0">
      <aside>
        <mat-horizontal-stepper labelPosition="bottom" [linear]="true" #stepper>
          <mat-step [stepControl]="stepCategory">
            <app-creator-step-category [formGroup]="stepCategory"></app-creator-step-category>
          </mat-step>
          <mat-step [stepControl]="stepView">
            <app-creator-step-view [stepCategory]="stepCategory" [formGroup]="stepView"></app-creator-step-view>
          </mat-step>
          <mat-step [stepControl]="stepDetails">
            <app-creator-step-details [formGroup]="stepDetails"></app-creator-step-details>
          </mat-step>
        </mat-horizontal-stepper>
      </aside>
    </div>
    <div class="col-lg-8 p-0 d-flex flex-column align-items-between">
      <app-creator-background-picker>
        <app-creator-preview
          class="d-none d-lg-flex"
          [stepCategory]="stepCategory"
          [stepView]="stepView"
          [stepDetails]="stepDetails"
          [stepper]="stepper">
        </app-creator-preview>
      </app-creator-background-picker>


      <app-creator-actions
        [stepper]="stepper"
        [stepCategory]="stepCategory"
        [stepView]="stepView"
        [stepDetails]="stepDetails">
      </app-creator-actions>
    </div>
  </div>
</div>
