import {Component, Input, OnInit} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {FormGroup} from '@angular/forms';

interface Step {
  name: string;
  invalid: boolean;
}

@Component({
  selector: 'app-creator-stepper',
  templateUrl: './creator-stepper.component.html',
  styleUrls: ['./creator-stepper.component.scss']
})
export class CreatorStepperComponent implements OnInit {
  @Input() stepper: MatStepper;
  @Input() stepCategoryInvalid: boolean;
  @Input() stepViewInvalid: boolean;
  @Input() stepDetailsInvalid: boolean;

  constructor() { }

  ngOnInit(): void {}

  handleStep(i: number): void {
    this.stepper.selectedIndex = i;
  }
}
