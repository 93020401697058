import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlContainer, FormBuilder, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {animalsData} from '../../creatorData';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-creator-step-view',
  templateUrl: './creator-step-view.component.html',
  styleUrls: ['./creator-step-view.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class CreatorStepViewComponent implements OnInit {
  @Input() stepCategory: FormGroup;
  images: string[];
  texts: string[];

  constructor(
    public parent: FormGroupDirective,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.parent.form.addControl('view', this.fb.group({
      image: ['panda1'],
      allowText: [true],
      text: ['REBBITS CAN`T PUKE'],
    }));

    this.updateImages();

    if (this.queryParams.image) {
      this.parent.form.get('view.image').patchValue(this.queryParams.image);
    }

    if (this.queryParams.text) {
      this.parent.form.get('view.text').patchValue(this.queryParams.text);
    }

    if (this.queryParams.allowText) {
      this.parent.form.get('view.allowText').patchValue(this.queryParams.allowText);
    }

    this.stepCategory.get('category.animal').valueChanges.subscribe(elem => {
      this.updateImages();
    });
  }

  get queryParams(): any {
    return this.route.snapshot.queryParams;
  }

  updateImages(): void {
    const animal = animalsData.find(elem => elem.name === this.stepCategory.get('category.animal').value);

    this.images = animal.images;
    this.texts = animal.texts;
    this.parent.form.get('view').patchValue({
      image: animal.images[0],
      text: animal.texts[0]
    });
  }

  handleText(text: string): void {
    this.parent.form.get('view.text').patchValue(text);
  }
}
