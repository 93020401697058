import {Injectable} from '@angular/core';
import {prices, sizes} from '../creatorData';
import axios from 'axios';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CreatorService {

  constructor(
    private http: HttpClient,
  ) {}

  addToCart(values): any {
    const index = sizes.indexOf(values.size);
    const price = prices[index].toString();

    const data = {
      name: `
        Personalizowane zamówienie -
        size: ${values.size},
      ` ,
      catalog_visibility: 'hidden',
      type: 'simple',
      regular_price: price,
      description: `
        category: ${values.animal}
        image: ${values.image}
        text: ${values.text}
        size: ${values.size}
        option ${values.option}
        type: ${values.type}
      `,
      categories: [],
      attributes: [],
      tags: []
    };

    // return this.http.post(`http://localhost:5000/cart/add`, data);
    return this.http.post(`${window.location.origin}/cart/add`, data);

  }
}
