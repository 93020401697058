<ng-container formGroupName="category">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 mb-3">
        <h3 class="font-size-18 dark-color text-uppercase m-0">WYBIERZ KATEGORIĘ:</h3>
      </div>
      <div *ngFor="let animal of animalNames" class="col-lg-6 col-sm-4 col-6 mb-4">
        <label
          [class.active]="animal === parent.form.get('category.animal').value"
          class="box">
          <img class="w-100" [src]="'assets/images/' + animal" />
          <input type="radio" [value]="animal" formControlName="animal">
          <span class="material-icons">
            done
          </span>
        </label>
      </div>
    </div>
  </div>
</ng-container>
