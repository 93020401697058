export const dogData = {
  name: 'category-dog.jpeg',
  texts: [
    'Istnieje ponad 150 ras psów, podzielonych na 8 klas.',
    'Szczenięta mają 28 zębów, a dorosłe psy 42.',
    'Według pewnych badań stwierdzono, że 45% psów śpi w łóżku właściciela.',
    'Psy mają 319 kości. Dla porównania człowiek ma tylko 206 kości.',
    'Kiedy szczeniak się rodzi, jest ślepy, głuchy i bezzębny.',
    'Były prezydent USA Theodore Roosevelt miał Pitbulla o imieniu Pete.',
    'Francuskie pudelki nie pochodziły z Francji, ale z Niemiec.',
    'Największą rasą psów jest Wilczarz irlandzki.',
    'Szacuje się, że na świecie jest 400 milionów psów.',
    'Najstarszy pies świata zmarł w wieku 29 lat.',
    '3 psy przetrwały zatonięcie Titanica.',
    'Psy zapamiętują kto dla ich opiekunów był niemiły i umieją chować urazę.',
    'Psy mogą wytrącić 70% wody z sierści w cztery sekundy.'
  ],
  images: ['dog_01.png', 'dog_02.png', 'dog_03.png', 'dog_04.png', 'dog_05.png', 'dog_06.png',
    'dog_07.png', 'dog_08.png', 'dog_09.png', 'dog_10.png', 'dog_11.png', 'dog_12.png', 'dog_13.png',
    'dog_14.png', 'dog_16.png', 'dog_17.png', 'dog_18.png', 'dog_19.png']
};

export const catData = {
  name: 'category-cat.jpeg',
  texts: [
    'Koty są najbardziej popularnym zwierzęciem domowych na świecie.',
    'Koty śpią średnio od 13 do 14 godzin dziennie.',
    'Koty domowe ważą zazwyczaj od 4 kilogramów do 5 kilogramów.',
    'Koty płci żeńskiej są zazwyczaj praworęczne, natomiast samce leworęczne.',
    'Wszystkie koty mają unikalne odciski nosa.',
    'Koty wydają ponad 100 różnych dźwięków.',
    'Isaac Newton wynalazł drzwi dla kotów.',
    'Większość kotów ma niechęć do wody.',
    'Koty mają największe oczy w stosunku do wielkości głowy wśród ssaków.',
    'Mruczenie kota może być formą samouzdrowienia dla kota.',
    'Kot nie widzi nic bezpośrednio pod swoim nosem.',
    'Koty nie komunikują się ze sobą przez miauczenie.',
    'Koty i ludzie mają prawie identyczne części mózgu, które kontrolują emocje.',
    'Koty witają się nawzajem dotykając swoje nosy.',
    'Przeciętnie koty żyją od 12 do 15 lat.',
    'Koty spędzają dużo czasu na lizaniu sierści, aby utrzymać czystość.',
    'Najstarszy film o kotach który znajdziemy na YouTube nakręcono w 1894 roku.',
    'Koty nie są w stanie posmakować słodyczy.',
    'Kot ma 230 kości, a 10% kości jest w jego ogonie.',
    'Najcięższy kot domowy w historii ważył 21 kilogramów.'
  ],
  images: ['cat_01.png', 'cat_02.png', 'cat_03.png', 'cat_04.png', 'cat_05.png', 'cat_06.png', 'cat_07.png', 'cat_08.png', 'cat_09.png', 'cat_10.png', 'cat_11.png']
};

export const pandaData = {
  name: 'category-panda.jpeg',
  texts: [
    'Nowo narodzona panda waży mniej niż telefon komórkowy.',
    'Panda wielka bywa nazywana niedźwiedziem bambusowym.',
    'Bambus! Ta soczysta roślina stanowi 99 procent diety pandy wielkiej.',
    'Gatunek tych zwierząt narażony jest na wyginięcie.',
    'Pandy wiodą spokojny i samotny tryb życia.',
    'Włosy dorosłej pandy mają długość około 10 cm.',
    'Pandy wielkie bardzo dużo jedzą, nawet do 40 kg na dzień.',
    'Pandy nie zapadają w sen zimowy,',
    'Pandy posiadają bardzo dobrze rozwinięty zmysł węchu.',
    'Dorosła panda w ciągu jednego dnia defekuje około 100 razy. Jej odchody ważą około 28 kg.',
    'Według naukowców pandy żyją na Ziemi od dwóch do trzech milionów lat.',
    'Wyróżniane są dwa rodzaje pand. Należą do nich panda wielka oraz panda mała.'
  ],
  images: ['panda_01.png', 'panda_02.png', 'panda_03.png', 'panda_04.png', 'panda_05.png', 'panda_06.png', 'panda_07.png', 'panda_08.png', 'panda_09.png', 'panda_10.png', 'panda_11.png', 'panda_12.png', 'panda_13.png', 'panda_14.png']
};

export const otherData = {
  name: 'category-other.png',
  texts: [
    'Istnieje gatunek meduz który jest uważany za nieśmiertelny.',
    'Pszczoły potrafią tańczyć aby wskazać innym cel.',
    'Pingwiny „oświadczają się” wybrance przynosząc jej ładne kamyki.',
    'Pot hipopotamów jest oleisty i działa jak nawilżający krem do opalania.',
    'Krowy produkują więcej mleka kiedy słuchają spokojnej muzyki.',
    'Krowy mają najlepszych przyjaciół i stresują się kiedy zostają rozdzielone.',
    'Małe słonie ssą własną trąbę tak jak ludzkie dzieci ssą kciuk.',
    'Pieski preriowe witają się ze sobą przez pocałunek.',
    'Kiedy słoń jest zestresowany, jego współtowarzysze ze stada będą głaskać go trąbami.',
    'Serce wieloryba bije tylko 9 razy na minutę.'
  ],
  images: ['other_01.png', 'other_02.png', 'other_03.png', 'other_04.png', 'other_05.png', 'other_06.png',
    'other_07.png', 'other_08.png', 'other_09.png', 'other_10.png']
};

export const animalsData = [catData, pandaData, dogData, otherData];
export const animalNames = animalsData.map(elem => elem.name);
export const types = ['plakat'];
export const options = ['papier matowy', 'papier błyszczący fotograficzny'];
export const sizes = ['21/30cm', '30/42cm', '42/60cm', '50/70cm', '60/84cm', '61/91cm', '70/100cm', '84/119cm', '100/140cm'];
export const prices = [34, 39, 44, 49, 58, 63, 79, 87, 90];
