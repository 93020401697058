import {Component, Inject, OnInit} from '@angular/core';
import {CreatorService} from "../../services/creator.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-creator-cart-dialog',
  templateUrl: './creator-cart-dialog.component.html',
  styleUrls: ['./creator-cart-dialog.component.scss']
})
export class CreatorCartDialogComponent implements OnInit {
  loading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private creatorService: CreatorService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
  }

  toCart(): void {
    this.loading = true;

    this.creatorService.addToCart(this.data).subscribe(
      (response) => {
        this.loading = false;
        window.location.replace(`https://kreatywnywarsztat.pl/koszyk/?add-to-cart=${response.id}`);
      },
      (err) => {
        this.loading = false;
        this.snackbar.open('BŁĄD WEWNĘTRZNY');
      }
    );
  }
}
