import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';
import {Animal} from '../../interfaces/animal';
import {animalsData} from '../../creatorData';

@Component({
  selector: 'app-creator-preview',
  templateUrl: './creator-preview.component.html',
  styleUrls: ['./creator-preview.component.scss']
})
export class CreatorPreviewComponent implements OnInit {
  @Input() stepper: MatStepper;
  @Input() stepCategory: FormGroup;
  @Input() stepView: FormGroup;
  @Input() stepDetails: FormGroup;

  constructor(
  ) { }

  ngOnInit(): void {
  }

  get animal(): Animal {
    return animalsData.find(elem => elem.name === this.stepCategory.get('category.animal').value);
  }

  get currentTextIndex(): number {
    const currentIndex = this.animal.texts.findIndex((element: string) =>
      element === this.stepView.get('view.text').value);

    return currentIndex;
  }

  get currentImageIndex(): number {
    const currentIndex = this.animal.images.findIndex((element: string) =>
      element === this.stepView.get('view.image').value);

    return currentIndex;
  }

  prevImage(): void {
    const currentindex = this.currentImageIndex;

    if (currentindex !== 0) {
      this.stepView.get('view.image').patchValue(this.animal.images[currentindex - 1]);
    }
  }

  nextImage(): void {
    const currentindex = this.currentImageIndex;

    if (currentindex !== this.animal.images.length - 1) {
      this.stepView.get('view.image').patchValue(this.animal.images[currentindex + 1]);
    }
  }

  prevText(): void {
    const currentIndex = this.currentTextIndex;

    if (currentIndex !== 0) {
      this.stepView.get('view.text').patchValue(this.animal.texts[currentIndex - 1]);
    }
  }

  nextText(): void {
    const currentIndex = this.currentTextIndex;

    if (currentIndex !== this.animal.texts.length - 1) {
      this.stepView.get('view.text').patchValue(this.animal.texts[currentIndex + 1]);
    }
  }

  handleRandom(): void {
    const randomText = Math.floor(Math.random() * this.animal.texts.length - 1) + 1;
    const randomImage = Math.floor(Math.random() * this.animal.images.length - 1) + 1;

    this.stepView.get('view').patchValue({
      image: this.animal.images[randomImage],
      text: this.animal.texts[randomText]
    });

  }
}
