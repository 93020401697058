import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreatorComponent} from './creator.component';
import {CreatorStepCategoryComponent} from './components/creator-step-category/creator-step-category.component';
import {CreatorStepViewComponent} from './components/creator-step-view/creator-step-view.component';
import {CreatorStepDetailsComponent} from './components/creator-step-details/creator-step-details.component';
import {CreatorPreviewComponent} from './components/creator-preview/creator-preview.component';
import {MaterialModule} from '../../material.module';
import {ReactiveFormsModule} from '@angular/forms';
import {CreatorStepperComponent} from './components/creator-stepper/creator-stepper.component';
import { CreatorActionsComponent } from './components/creator-actions/creator-actions.component';
import { CreatorPreviewDialogComponent } from './components/creator-preview-dialog/creator-preview-dialog.component';
import { CreatorPriceComponent } from './components/creator-price/creator-price.component';
import {HttpClientModule} from "@angular/common/http";
import { CreatorBackgroundPickerComponent } from './components/creator-background-picker/creator-background-picker.component';
import { CreatorCartDialogComponent } from './components/creator-cart-dialog/creator-cart-dialog.component';

@NgModule({
  declarations: [
    CreatorComponent,
    CreatorStepCategoryComponent,
    CreatorStepViewComponent,
    CreatorStepDetailsComponent,
    CreatorPreviewComponent,
    CreatorStepperComponent,
    CreatorActionsComponent,
    CreatorPreviewDialogComponent,
    CreatorPriceComponent,
    CreatorBackgroundPickerComponent,
    CreatorCartDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
  ]
})
export class CreatorModule {
}
