<section class="d-flex flex-column justify-content-end align-items-end">
  <article class="border border-light">
    <figure class="m-0">
      <span [class.disabled]="currentImageIndex === 0" (click)="prevImage()" class="material-icons btn-arrow btn-arrow-left font-size-38 cursor-pointer ">
        keyboard_arrow_left
      </span>
      <img class="w-100" [src]="'assets/images/' + stepView.get('view.image').value">
      <span
        [class.disabled]="currentImageIndex === animal.images.length-1"
        (click)="nextImage()" class="material-icons btn-arrow btn-arrow-right font-size-38 cursor-pointer ">
        keyboard_arrow_right
      </span>
    </figure>

    <footer *ngIf="stepView.get('view.allowText').value" class="d-flex align-items-center justify-content-center">
      <span
        [class.disabled]="currentTextIndex === 0"
        (click)="prevText()"
        class="material-icons btn-arrow btn-arrow-left font-size-38 cursor-pointer ">
        keyboard_arrow_left
      </span>
      <p class="secondary-font word-break mb-0 mt-3 text-uppercase text-center font-size-16">
        {{stepView.get('view.text').value}}
      </p>
      <span
        [class.disabled]="currentTextIndex === animal.texts.length-1"
        (click)="nextText()"
        class="material-icons btn-arrow btn-arrow-right font-size-38 cursor-pointer ">
        keyboard_arrow_right
      </span>
    </footer>
  </article>

  <button class="ml-2 px-3 py-2 mt-3 btn btn-main" (click)="handleRandom()">Losuj zestaw</button>
</section>
