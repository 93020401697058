import { Component, OnInit } from '@angular/core';
import {ControlContainer, FormBuilder, FormGroupDirective, Validators} from '@angular/forms';
import {options, types, sizes} from '../../creatorData';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-creator-step-details',
  templateUrl: './creator-step-details.component.html',
  styleUrls: ['./creator-step-details.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class CreatorStepDetailsComponent implements OnInit {
  types: string[] = types;
  options: string[] = options;
  sizes: string[] = sizes;

  constructor(
    public parent: FormGroupDirective,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.parent.form.addControl('details', this.fb.group({
      type: [types[0] , {validators: [Validators.required]}],
      option: [options[0] , {validators: [Validators.required]}],
      size: [sizes[0] , {validators: [Validators.required]}],
    }));

    if (this.queryParams.type) {
      this.parent.form.get('details.type').patchValue(this.queryParams.type);
    }

    if (this.queryParams.option) {
      this.parent.form.get('details.option').patchValue(this.queryParams.option);
    }

    if (this.queryParams.size) {
      this.parent.form.get('details.size').patchValue(this.queryParams.size);
    }
  }

  get queryParams(): any {
    return this.route.snapshot.queryParams;
  }
}
