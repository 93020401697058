import {Component, Input, OnInit} from '@angular/core';
import { sizes, prices } from '../../creatorData';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-creator-price',
  templateUrl: './creator-price.component.html',
  styleUrls: ['./creator-price.component.scss']
})
export class CreatorPriceComponent implements OnInit {
  @Input() controlSize: FormGroup;
  price = prices[0];

  constructor() { }

  ngOnInit(): void {
    this.controlSize.valueChanges.subscribe(elem => {
      this.setPrice(elem);
    });
  }

  setPrice(elem: string): void {
    const index = sizes.indexOf(elem);
    this.price = prices[index];
  }
}
