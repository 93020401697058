import {Component, OnInit} from '@angular/core';
import {ControlContainer, FormBuilder, FormGroupDirective, Validators} from '@angular/forms';
import {animalNames} from '../../creatorData.js';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-creator-step-category',
  templateUrl: './creator-step-category.component.html',
  styleUrls: ['./creator-step-category.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class CreatorStepCategoryComponent implements OnInit {
  animalNames = animalNames;

  constructor(
    public parent: FormGroupDirective,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.parent.form.addControl('category', this.fb.group({
      animal: [animalNames[0], {validators: [Validators.required]}],
    }));

    if (this.queryParams.category) {
      this.parent.form.get('category.animal').patchValue(this.queryParams.category);
    }
  }

  get queryParams(): any {
    return this.route.snapshot.queryParams;
  }
}
