<div class="container-fluid white-bg">
  <div class="row">
    <div class="col-lg-12 d-flex justify-content-between align-items-center">
      <button [class.active]="stepper.selectedIndex === 0" class="px-3 py-2 btn btn-main" (click)="handleStep(0)">
        Kategoria
      </button>
      <div class="separator"></div>
      <button [class.active]="stepper.selectedIndex === 1" class="px-3 py-2 btn btn-main" (click)="handleStep(1)" >
        Wygląd
      </button>
      <div class="separator"></div>
      <button [class.active]="stepper.selectedIndex === 2" class="px-3 py-2 btn btn-main" (click)="handleStep(2)">
        Detale
      </button>
    </div>
  </div>
</div>
