
<h3 class="font-size-18 mb-2 dark-color text-uppercase m-0">To już ostatni krok!</h3>

<p class="word-break mb-5 gray-color font-size-14">Sprawdź czy wszystko jest zgodnie z Twoim pomysłem. Jeśli potrzebujesz coś poprawić to kliknij w przycisk <span class="dark-color">ANULUJ</span>, jeśli jesteś gotowy by zamówić to kliknij w <span class="dark-color">PRZEJDŹ DO KOSZYKA</span> by sfinalizować swoje zamówienie.</p>

<footer class="d-flex justify-content-end">
  <button mat-dialog-close class="px-3 py-2 btn mr-2">Anuluj</button>
  <button [class.loading]="loading" class="px-3 py-2 btn btn-main position-relative" (click)="toCart()">
    <svg version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="25 25 50 50">
      <circle cx="50" cy="50" r="20" fill="none" stroke-width="5" stroke="#ffffff" stroke-linecap="round" stroke-dashoffset="0" stroke-dasharray="100, 200">
        <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 50 50" to="360 50 50" dur="2.5s" repeatCount="indefinite"/>
        <animate attributeName="stroke-dashoffset" values="0;-30;-124" dur="1.25s" repeatCount="indefinite"/>
        <animate attributeName="stroke-dasharray" values="0,200;110,200;110,200" dur="1.25s" repeatCount="indefinite"/>
      </circle>
    </svg>
    <span >Przejdź do koszyka</span>
  </button>

</footer>
