<ng-container formGroupName="details">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 mb-3">
        <h3 class="font-size-18 dark-color text-uppercase m-0">WYBIERZ TYP:</h3>
      </div>
      <div *ngFor="let type of types" class="col-lg-6 col-sm-4 col-6 mb-4">
        <label
          [class.active]="type === parent.form.get('details.type').value"
          class="box">
          <input type="radio" [value]="type" formControlName="type">
          <span class="font-size-12 text-uppercase">{{type}}</span>
          <span class="material-icons">
            done
          </span>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3">
        <h3 class="font-size-18 dark-color text-uppercase m-0">WYBIERZ OPCJE:</h3>
      </div>
      <div *ngFor="let option of options" class="col-lg-6 col-sm-4 col-6 mb-4">
        <label
          [class.active]="option === parent.form.get('details.option').value"
          class="box">
          <input type="radio" [value]="option" formControlName="option">
          <span class="font-size-12 text-uppercase">{{option}}</span>
          <span class="material-icons">
            done
          </span>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3">
        <h3 class="font-size-18 dark-color text-uppercase m-0">WYBIERZ ROZMIAR:</h3>
      </div>
      <div *ngFor="let size of sizes" class="col-lg-6col-lg-6 col-sm-4 col-6 mb-4">
        <label
          [class.active]="size === parent.form.get('details.size').value"
          class="box">
          <input type="radio" [value]="size" formControlName="size">
          <span class="font-size-12 text-uppercase">{{size}}</span>
          <span class="material-icons">
            done
          </span>
        </label>
      </div>
    </div>
  </div>

</ng-container>
