import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';

@Component({
  selector: 'app-creator',
  templateUrl: './creator.component.html',
  styleUrls: ['./creator.component.scss']
})
export class CreatorComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  stepCategory: FormGroup;
  stepView: FormGroup;
  stepDetails: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.stepCategory = this.fb.group({});
    this.stepView = this.fb.group({});
    this.stepDetails = this.fb.group({});
  }
}
