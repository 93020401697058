import {Component, HostBinding, OnInit} from '@angular/core';

interface Background {
  name: string;
  background: string;
}

const backgrounds = [{
  name: 'Brak tła',
  background: 'background-empty'
}, {
  name: 'Chropowata ściana',
  background: 'background-1'
}, {
  name: 'Cegły',
  background: 'background-2'
}];

@Component({
  selector: 'app-creator-background-picker',
  templateUrl: './creator-background-picker.component.html',
  styleUrls: ['./creator-background-picker.component.scss'],
})
export class CreatorBackgroundPickerComponent implements OnInit {
  @HostBinding('class') background: string = backgrounds[0].background;
  backgrounds: Background[] = backgrounds;
  constructor(
  ) { }

  ngOnInit(): void {
  }

  selectBackground(item): void {
    this.background = item.background;
  }
}
