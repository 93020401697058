<ng-container formGroupName="view">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab label="Obrazek">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 my-3">
            <h3 class="font-size-18 dark-color text-uppercase m-0">WYBIERZ OBRAZEK:</h3>
          </div>
          <div *ngFor="let image of images" class="col-lg-6 col-sm-4 col-6 mb-4">
            <label
              [class.active]="image === parent.form.get('view.image').value"
              class="box">
              <img class="w-100" [src]="'assets/images/' + image" />
              <input type="radio" [value]="image" formControlName="image">
              <span class="material-icons">
            done
          </span>
            </label>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Tekst">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 mt-4">
            <mat-checkbox formControlName="allowText">Dodaj tekst</mat-checkbox>
          </div>
          <div *ngIf="parent.form.get('view.allowText').value" class="col-12 my-3">
            <h3 class="font-size-18 dark-color text-uppercase m-0">WPROWADŹ WŁASNY TEKST:</h3>
          </div>
          <div *ngIf="parent.form.get('view.allowText').value" class="col-12 d-flex align-items-end flex-column">
            <mat-form-field  class="w-100 mt-2 font-size-14">
              <textarea [maxLength]="80" matInput placeholder="Własny tekst" formControlName="text"></textarea>
            </mat-form-field>
            <span class="gray-color font-size-12">{{parent.form.get('view.text').value.length}}/80</span>
          </div>
        </div>
        <div *ngIf="parent.form.get('view.allowText').value" class="row">
          <div class="col-12 my-3">
            <h3 class="font-size-18 dark-color text-uppercase m-0">LUB WYBIERZ GOTOWY:</h3>
          </div>
          <div *ngFor="let text of texts" class="col-lg-12 mb-2">
            <button
              (click)="handleText(text)"
              [class.active]="text === parent.form.get('view.text').value"
              class="box w-100">
              <span class="font-size-12 text-left text-uppercase">{{text}}</span>
              <span class="material-icons">
                done
              </span>
            </button>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</ng-container>
