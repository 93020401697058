<button class="px-3 py-2 btn btn-main btn-background" [matMenuTriggerFor]="menu">
  Zmień tło
</button>
<mat-menu #menu="matMenu">
  <button [disabled]="item.background === background" (click)="selectBackground(item)" *ngFor="let item of backgrounds"  mat-menu-item>
    <span>{{item.name | uppercase}}</span>
  </button>
</mat-menu>

<ng-content></ng-content>
